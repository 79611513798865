import axios from "axios";

export const apiSignup = (email, name, password) => {
  return axios.post("/api/user/signup", {
    email: email,
    name: name,
    password: password,
  });
};

export const apiLogin = (email, password) => {
  return axios.post("/api/auth/login", {
    email: email,
    password: password,
  });
};

export const apiLogout = () => {
  return axios.post("/api/secured/auth/logout");
};

export const apiFetchUserInfo = () => {
  return axios.get("/api/secured/user/info").then(({ data }) => data);
};
