import React from "react";

import { ThemeProvider } from "@mui/material";
import App from "App";
import { ErrorProvider } from "contexts/ErrorContext";
import { LoadingProvider } from "contexts/LoadingContext";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "store";
import theme from "theme";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <ErrorProvider>
          <App />
        </ErrorProvider>
      </LoadingProvider>
    </ThemeProvider>
  </Provider>,
);
