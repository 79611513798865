import { createSlice } from "@reduxjs/toolkit";
import { showModuleName } from "pages/Show/store/showConstants";
import { fetchShowThunk } from "pages/Show/store/showThunks";

const initialState = { show: null, episodesChecksum: null };

const getEpisodeListChecksum = (episodes) => {
  return episodes
    .map((episode) =>
      episode.userData
        ? `${episode.id}${episode.userData.isViewed ? "+" : "-"}`
        : episode.id,
    )
    .join(",");
};

const showSlice = createSlice({
  name: showModuleName,
  initialState,
  reducers: {
    clearShowAction: (state) => {
      state.show = null;
    },
    inMemoryChangeEpisodeViewStatusAction: (state, { payload }) => {
      const updatedShow = { ...state.show };
      updatedShow.episodes = updatedShow.episodes.map((episode) =>
        episode.id === payload.id
          ? {
              ...episode,
              userData: { ...episode.episode, isViewed: !payload.viewed },
            }
          : episode,
      );
      state.show = updatedShow;
      state.episodesChecksum = getEpisodeListChecksum(updatedShow.episodes);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShowThunk.fulfilled, (state, action) => {
      const show = action.payload;
      state.show = show;
      if (show) {
        state.episodesChecksum = getEpisodeListChecksum(show.episodes);
      }
    });
  },
});

export const { clearShowAction, inMemoryChangeEpisodeViewStatusAction } =
  showSlice.actions;

export default showSlice.reducer;
