import React, { useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { apiSignup } from "session/api/sessionApi";
import { VisibleStyledLink } from "styled";
import { handleApiError } from "utils";

const SignupPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPass] = useState("");

  useEffect(() => {
    document.title = "Signup | MySeries";
  });

  const signup = useCallback(async () => {
    try {
      await apiSignup(email, name, password);
      navigate("/");
    } catch (error) {
      handleApiError(error);
    }
  }, [email, name, password]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="i_email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="i_name"
            label="Name"
            name="name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="i_password1"
            label="Password"
            type="password"
            name="password1"
            onChange={(event) => {
              setPass(event.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="i_password2"
            label="Repeat Password"
            type="password"
            name="password2"
          />
          <Button
            onClick={signup}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            id="b_signup"
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={VisibleStyledLink} to="/privacy" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={VisibleStyledLink} to="/login" variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupPage;
