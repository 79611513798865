import axios from "axios";

export const apiFetchShowInfo = (id) => {
  return axios.get(`/api/show/${id}`).then(({ data }) => data);
};

export const apiSubscribeShow = (id) => {
  return axios.put(`/api/secured/show/${id}/subscribers`);
};

export const apiUnsubscribeShow = (id) => {
  return axios.delete(`/api/secured/show/${id}/subscribers`);
};

export const apiStopWatchingShow = (id) => {
  return axios.put(`/api/secured/show/${id}/subscribers/stop`);
};

export const apiResumeWatchingShow = (id) => {
  return axios.put(`/api/secured/show/${id}/subscribers/resume`);
};
