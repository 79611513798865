import axios from "axios";

export const setupAxios = (setLoading, setError, setErrorOpen) => {
  const requestInterceptor = axios.interceptors.request.use((config) => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 250);

    return {
      ...config,
      timeoutId,
    };
  });

  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      clearTimeout(response.config.timeoutId);
      setLoading(false);
      return response;
    },
    (error) => {
      clearTimeout(error.config.timeoutId);
      setLoading(false);
      setError(
        error.response?.data?.detail || "An unexpected server error occurred",
      );
      setErrorOpen(true);
      error.handled = true;
      return Promise.reject(error);
    },
  );

  return () => {
    axios.interceptors.request.eject(requestInterceptor);
    axios.interceptors.response.eject(responseInterceptor);
  };
};
