import React from "react";

import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { StyledLink } from "styled";

const ShowCard = ({ show }) => {
  const { id, name, imdbRating, pictureUrl } = show;
  return (
    <StyledLink to={`/show/${id}`}>
      <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={name}
            height="255"
            image={pictureUrl ? pictureUrl : "/default-pic.png"}
            title={name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" noWrap>
              {name}
            </Typography>
            <Typography>{`IMDB: ${imdbRating ? imdbRating : "Unknown"}`}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </StyledLink>
  );
};

ShowCard.propTypes = {
  show: PropTypes.object,
};

export default ShowCard;
