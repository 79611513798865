import React, { useCallback } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  apiMarkEpisodeNotViewed,
  apiMarkEpisodeViewed,
  apiMarkSeasonNotViewed,
  apiMarkSeasonViewed,
} from "pages/NewEpisodes/api/newEpisodes";
import { inMemoryChangeEpisodeViewStatusAction } from "pages/Show/store/showSlice";
import { fetchShowThunk } from "pages/Show/store/showThunks";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { VisibleStyledLink } from "styled";
import { handleApiError } from "utils";

const SeasonsTable = React.memo(({ showId, seasons }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.session);

  const createEpisodeCheckboxClickHandler = useCallback(
    (id, viewed) => async () => {
      if (viewed) {
        await apiMarkEpisodeNotViewed(id);
      } else {
        await apiMarkEpisodeViewed(id);
      }
      dispatch(inMemoryChangeEpisodeViewStatusAction({ id, viewed }));
    },
    [],
  );

  const createSeasonCheckboxClickHandler = useCallback(
    (seasonNumber, viewed) => async () => {
      try {
        if (viewed) {
          await apiMarkSeasonNotViewed(showId, seasonNumber);
        } else {
          await apiMarkSeasonViewed(showId, seasonNumber);
        }
        dispatch(fetchShowThunk(showId));
      } catch (error) {
        handleApiError(error);
      }
    },
    [showId],
  );

  const isDateInFuture = (date) => {
    return new Date(date) > new Date();
  };

  return (
    <TableContainer>
      <Table aria-label="simple table" size="small">
        <TableBody>
          {seasons.map((season) => {
            return (
              <React.Fragment key={`season_${season.seasonNumber}`}>
                <TableRow>
                  <TableCell colSpan={3} align="right">
                    <Typography gutterBottom variant="h5">
                      {`Season ${season.seasonNumber}`}
                    </Typography>
                  </TableCell>
                  {loggedIn && season.userData ? (
                    <TableCell
                      align="center"
                      onClick={createSeasonCheckboxClickHandler(
                        season.seasonNumber,
                        season.userData.isViewed,
                      )}
                    >
                      {season.userData.isViewed ? (
                        <CheckCircleIcon />
                      ) : (
                        <CheckCircleOutlineIcon style={{ color: "grey" }} />
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
                {season.episodes.map((episode) => {
                  const rowTextStyle = isDateInFuture(episode.date)
                    ? { color: "grey" }
                    : {};
                  return (
                    <TableRow key={episode.id}>
                      <TableCell style={rowTextStyle}>
                        {episode.number}
                      </TableCell>
                      <TableCell style={{ ...rowTextStyle, maxWidth: "120px" }}>
                        <VisibleStyledLink to={`/episode/${episode.id}`}>
                          {episode.name}
                        </VisibleStyledLink>
                      </TableCell>
                      <TableCell align="right" style={rowTextStyle}>
                        {<Moment format="DD MMM YYYY">{episode.date}</Moment>}
                      </TableCell>
                      {loggedIn && episode.userData ? (
                        <TableCell
                          align="center"
                          onClick={createEpisodeCheckboxClickHandler(
                            episode.id,
                            episode.userData.isViewed,
                          )}
                        >
                          {episode.userData.isViewed ? (
                            <CheckCircleIcon />
                          ) : (
                            <CheckCircleOutlineIcon style={{ color: "grey" }} />
                          )}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

SeasonsTable.displayName = "SeasonsTable";

SeasonsTable.propTypes = {
  showId: PropTypes.number,
  seasons: PropTypes.array,
};

export default SeasonsTable;
