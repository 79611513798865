import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchEpisodeInfo } from "pages/Episode/api/episodeApi";
import { episodeModuleName } from "pages/Episode/store/episodeConstants";
import { handleApiError } from "utils";

export const fetchEpisodeThunk = createAsyncThunk(
  `${episodeModuleName}/fetchEpisodeThunk`,
  async (id) => {
    try {
      return await apiFetchEpisodeInfo(id);
    } catch (error) {
      handleApiError(error);
    }
  },
);
