import { combineReducers, configureStore } from "@reduxjs/toolkit";
import episodeReducer from "pages/Episode/store/episodeSlice";
import newEpisodesReducer from "pages/NewEpisodes/store/newEpisodesSlice";
import searchReducer from "pages/Search/store/searchSlice";
import showReducer from "pages/Show/store/showSlice";
import showListReducer from "pages/ShowList/store/showListSlice";
import sessionReducer from "session/store/sessionSlice";

const rootReducer = combineReducers({
  session: sessionReducer,
  showList: showListReducer,
  show: showReducer,
  episode: episodeReducer,
  newEpisodes: newEpisodesReducer,
  search: searchReducer,
});

export default configureStore({
  reducer: rootReducer,
});
