import { createSlice } from "@reduxjs/toolkit";
import { newEpisodesModuleName } from "pages/NewEpisodes/store/newEpisodesConstants";
import { fetchNewEpisodesThunk } from "pages/NewEpisodes/store/newEpisodesThunks";

const initialState = { newEpisodes: null };

const newEpisodesSlice = createSlice({
  name: newEpisodesModuleName,
  initialState,
  reducers: {
    clearNewEpisodesAction: (state) => {
      state.newEpisodes = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewEpisodesThunk.fulfilled, (state, { payload }) => {
      state.newEpisodes = payload;
    });
  },
});

export const { clearNewEpisodesAction } = newEpisodesSlice.actions;

export default newEpisodesSlice.reducer;
