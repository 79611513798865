import React, { useCallback } from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import { fetchEpisodeThunk } from "pages/Episode/store/episodeThunks";
import {
  apiMarkEpisodeNotViewed,
  apiMarkEpisodeViewed,
} from "pages/NewEpisodes/api/newEpisodes";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { handleApiError } from "utils";

const ViewButton = React.memo(({ id, isViewed }) => {
  const dispatch = useDispatch();

  const createClickHandler = useCallback(
    (viewed) => async () => {
      try {
        if (viewed) {
          await apiMarkEpisodeNotViewed(id);
        } else {
          await apiMarkEpisodeViewed(id);
        }
        dispatch(fetchEpisodeThunk(id));
      } catch (error) {
        handleApiError(error);
      }
    },
    [id],
  );

  let onClick = createClickHandler(isViewed);

  return (
    <Button
      variant={isViewed ? "outlined" : "contained"}
      onClick={onClick}
      endIcon={isViewed ? <DoneIcon /> : null}
    >
      {isViewed ? "VIEWED" : "MARK VIEWED"}
    </Button>
  );
});

ViewButton.displayName = "ViewButton";

ViewButton.propTypes = {
  id: PropTypes.number,
  isViewed: PropTypes.bool,
};

export default ViewButton;
