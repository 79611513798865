import React, { useMemo } from "react";

import DoneIcon from "@mui/icons-material/Done";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import AddButton from "pages/Show/components/ShowInfoBlock/AddButton";
import PauseButton from "pages/Show/components/ShowInfoBlock/PauseButton";
import { useSelector } from "react-redux";
import { VerticalDiv } from "styled";

const isFullyWatchedShow = (episodes) => {
  let fullyWatched = true;
  episodes.forEach((episode) => {
    if (!episode.userData || !episode.userData.isViewed) {
      fullyWatched = false;
    }
  });
  return fullyWatched;
};

const ShowInfoBlock = () => {
  const { loggedIn } = useSelector((state) => state.session);
  const { show, episodesChecksum } = useSelector((state) => state.show);
  const episodes = show ? show.episodes : [];

  let isFullyWatched = useMemo(
    () => isFullyWatchedShow(episodes),
    [episodesChecksum],
  );

  if (!show) {
    return null;
  }

  const {
    id,
    status,
    year,
    imdbRating,
    country,
    genres,
    users,
    totalUsers,
    episodeDuration,
    totalDuration,
    userData,
  } = show;

  return (
    <VerticalDiv>
      <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableBody>
            <TableRow>
              <TableCell>Air date:</TableCell>
              <TableCell>{year}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status:</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country:</TableCell>
              <TableCell>{country}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Genres:</TableCell>
              <TableCell>{genres}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Viewers:</TableCell>
              <TableCell>
                {users} of {totalUsers}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Episode:</TableCell>
              <TableCell>{episodeDuration} m.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total:</TableCell>
              <TableCell>{totalDuration} min.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IMDB:</TableCell>
              <TableCell>{imdbRating ? imdbRating : "Unknown"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {loggedIn && userData ? (
        <AddButton id={id} isSubscribed={userData.isSubscribed} />
      ) : null}
      {loggedIn && userData && userData.isSubscribed ? (
        <>
          {isFullyWatched && userData.isActive ? (
            <Button
              disabled={true}
              sx={{ width: "200px" }}
              variant="outlined"
              endIcon={<DoneIcon />}
            >
              FULLY WATCHED
            </Button>
          ) : (
            <PauseButton id={id} isActive={userData.isActive} />
          )}
        </>
      ) : null}
    </VerticalDiv>
  );
};

export default ShowInfoBlock;
