import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchShowsPage } from "pages/ShowList/api/showListApi";
import { showListModuleName } from "pages/ShowList/store/showListConstants";
import { handleApiError } from "utils";

export const fetchShowsListThunk = createAsyncThunk(
  `${showListModuleName}/fetchShowsListThunk`,
  async ({ category, pageNumber }) => {
    try {
      return await apiFetchShowsPage(category, pageNumber);
    } catch (error) {
      handleApiError(error);
    }
  },
);
