import React, { useEffect } from "react";

import Typography from "@mui/material/Typography";
import { PageGrid, TitleTypography } from "styled";

const AboutPage = () => {
  useEffect(() => {
    document.title = "About | MySeries";
  });

  return (
    <PageGrid container>
      <TitleTypography variant="h5" gutterBottom>
        About Myseries
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Welcome to Myseries, your ultimate TV series tracker! Whether
        you`&apos;`re a casual viewer or a dedicated binge-watcher, Myseries
        offers a personalized experience to keep track of your favorite TV shows
        discover new ones.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        What Can You Do on Myseries?
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Here’s what you can do once you join our community:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            <strong>Create an Account:</strong> Sign up for free using our
            simple registration form. All you need is an email address to get
            started.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Follow Your Favorite Series:</strong> Once registered, you
            can follow any TV series and start tracking the episodes you’ve
            watched.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Discover New Episodes:</strong> Visit the `&apos;`New
            Episodes`&apos;` page to see newly released episodes of the series
            you are following.
          </Typography>
        </li>
      </ul>

      <TitleTypography variant="h6" gutterBottom>
        How to Use Myseries?
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Using Myseries is simple. Here’s how you can make the most out of your
        experience:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            <strong>Track Your Watched Episodes:</strong> Easily mark episodes
            you`&apos;`ve watched, keeping your series list up-to-date.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Explore:</strong> Browse through our extensive database of
            TV series and episodes to find new shows tailored to your tastes.
          </Typography>
        </li>
      </ul>

      <TitleTypography variant="h6" gutterBottom>
        Why Choose Myseries?
      </TitleTypography>

      <Typography variant="body1" paragraph>
        We’re dedicated to providing a user-friendly platform that respects your
        privacy and enhances your viewing experience. With Myseries, missing an
        episode or losing track of your favorite shows is a thing of the past!
      </Typography>

      <Typography variant="body1" paragraph>
        Thank you for choosing Myseries. We hope you enjoy your journey through
        the world of TV with us!
      </Typography>

      <Typography variant="body1">Myseries, Kyiv (Ukraine)</Typography>

      <Typography variant="body1">
        <a
          href="mailto:support.myseries@icloud.com"
          style={{ color: "primary" }}
        >
          support.myseries@icloud.com
        </a>
      </Typography>
    </PageGrid>
  );
};

export default AboutPage;
