import React from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import ViewButton from "pages/Episode/components/EpisodeInfoBlock/ViewButton";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HorizontalGrid, VerticalDiv } from "styled";

const EpisodeInfoBlock = () => {
  const { episode } = useSelector((state) => state.episode);
  const { loggedIn } = useSelector((state) => state.session);

  if (!episode) {
    return null;
  }

  const { id, date, totalViews, show, userData, prevId, nextId } = episode;

  return (
    <VerticalDiv>
      <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableBody>
            <TableRow>
              <TableCell>Date:</TableCell>
              <TableCell>
                {date ? (
                  <Moment format="DD MMM YYYY HH:mm A">{date}</Moment>
                ) : (
                  "NO DATE"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total views:</TableCell>
              <TableCell>{totalViews}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Duration:</TableCell>
              <TableCell>{show.episodeDuration}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {loggedIn && userData ? (
        <ViewButton id={id} isViewed={userData.isViewed} />
      ) : null}
      <HorizontalGrid container>
        {prevId ? (
          <Link to={`/episode/${prevId}`}>
            <Button variant="outlined" startIcon={<KeyboardArrowLeftIcon />}>
              PREV
            </Button>
          </Link>
        ) : (
          <Button
            disabled={true}
            variant="outlined"
            startIcon={<KeyboardArrowLeftIcon />}
          >
            PREV
          </Button>
        )}
        {nextId ? (
          <Link to={`/episode/${nextId}`}>
            <Button variant="outlined" endIcon={<KeyboardArrowRightIcon />}>
              NEXT
            </Button>
          </Link>
        ) : (
          <Button
            disabled={true}
            variant="outlined"
            endIcon={<KeyboardArrowRightIcon />}
          >
            NEXT
          </Button>
        )}
      </HorizontalGrid>
    </VerticalDiv>
  );
};

export default EpisodeInfoBlock;
