import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchNewEpisodes } from "pages/NewEpisodes/api/newEpisodes";
import { newEpisodesModuleName } from "pages/NewEpisodes/store/newEpisodesConstants";
import { handleApiError } from "utils";

export const fetchNewEpisodesThunk = createAsyncThunk(
  `${newEpisodesModuleName}/fetchNewEpisodes`,
  async () => {
    try {
      return await apiFetchNewEpisodes();
    } catch (error) {
      handleApiError(error);
    }
  },
);
