import React, { useEffect, useMemo } from "react";

import EpisodePage from "pages/Episode/EpisodePage";
import { fetchEpisodeThunk } from "pages/Episode/store/episodeThunks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const EpisodePageWrapper = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchEpisodeThunk(id));
  }, [dispatch, id]);

  return useMemo(() => <EpisodePage />, []);
};

export default EpisodePageWrapper;
