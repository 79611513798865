import React, { useCallback } from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import { apiSubscribeShow, apiUnsubscribeShow } from "pages/Show/api/showApi";
import { fetchShowThunk } from "pages/Show/store/showThunks";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { handleApiError } from "utils";

const AddButton = React.memo(({ id, isSubscribed }) => {
  const dispatch = useDispatch();

  const createClickHandler = useCallback(
    (isSubscribed) => async () => {
      try {
        if (isSubscribed) {
          await apiUnsubscribeShow(id);
        } else {
          await apiSubscribeShow(id);
        }
        dispatch(fetchShowThunk(id));
      } catch (error) {
        handleApiError(error);
      }
    },
    [id],
  );

  let onClick = createClickHandler(isSubscribed);

  return (
    <div>
      <Button
        sx={{ width: "200px" }}
        variant={isSubscribed ? "outlined" : "contained"}
        onClick={onClick}
        endIcon={isSubscribed ? <DoneIcon /> : null}
      >
        {isSubscribed ? "ADDED" : "ADD"}
      </Button>
    </div>
  );
});

AddButton.displayName = "AddButton";

AddButton.propTypes = {
  id: PropTypes.number,
  isSubscribed: PropTypes.bool,
};

export default AddButton;
