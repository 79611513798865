import React, { createContext, useContext, useState } from "react";

import { Alert, Snackbar } from "@mui/material";
import PropTypes from "prop-types";

const ErrorContext = createContext({
  error: "",
  setError: () => {},
  errorOpen: false,
  setErrorOpen: () => {},
});

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  const onClose = (event, reason) => {
    if (reason !== "clickaway") {
      setErrorOpen(false);
    }
  };

  return (
    <ErrorContext.Provider value={{ error, setError, errorOpen, setErrorOpen }}>
      {children}
      {errorOpen && (
        <Snackbar open={errorOpen} autoHideDuration={30000} onClose={onClose}>
          <Alert onClose={onClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node,
};

export const useError = () => useContext(ErrorContext);
