import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export const PageGrid = styled(Grid)(({ theme, wide }) => ({
  paddingTop: theme.spacing(2),
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  margin: "0 auto",
  maxWidth: wide
    ? "90%"
    : `min(calc(${theme.columnWidth * 2}px + ${theme.spacing(2)}), 90%)`,
  "@media (max-width: 768px)": {
    maxWidth: wide ? "90%" : `min(calc(${theme.columnWidth}px), 90%)`,
  },
}));

export const AutoFitGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  justifyContent: "center",
  gap: theme.spacing(2),
}));

export const VerticalDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const HorizontalGrid = styled(Grid)(({ theme, justifyContent }) => ({
  flexDirection: "row",
  justifyContent: justifyContent ? justifyContent : "center",
  alignItems: "flex-start",
  gap: theme.spacing(2),
}));

export const FixedWidthGridItem = styled(Grid)(({ theme, width }) => ({
  minWidth: width ? width : theme.columnWidth,
  maxWidth: width ? width : theme.columnWidth,
}));

export const StyledLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit",
}));

export const VisibleStyledLink = styled(Link)(() => ({
  color: "inherit",
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const DescriptionTypography = styled(Typography)(() => ({
  textAlign: "justify",
}));
