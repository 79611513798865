import axios from "axios";

export const apiFetchNewEpisodes = () => {
  return axios.get("/api/secured/episodes/new-list").then(({ data }) => data);
};

export const apiMarkEpisodeViewed = (id) => {
  return axios.put(`/api/secured/episode/${id}/views`);
};

export const apiMarkEpisodeNotViewed = (id) => {
  return axios.delete(`/api/secured/episode/${id}/views`);
};

export const apiMarkSeasonViewed = (showId, seasonNumber) => {
  return axios.put(`/api/secured/show/${showId}/season/${seasonNumber}/views`);
};

export const apiMarkSeasonNotViewed = (showId, seasonNumber) => {
  return axios.delete(
    `/api/secured/show/${showId}/season/${seasonNumber}/views`,
  );
};
