import React, { useEffect } from "react";

import ShowsList from "components/ShowsList/ShowsList";
import { clearSearchResultsAction } from "pages/Search/store/searchSlice";
import { searchShowsThunk } from "pages/Search/store/searchThunks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PageGrid, TitleTypography } from "styled";

const SearchPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchStr = searchParams.get("searchStr");

  const { searchResults } = useSelector((state) => state.search);

  useEffect(() => {
    document.title = `Search: ${searchStr} | MySeries`;
    dispatch(searchShowsThunk(searchStr));
  }, [searchStr]);

  useEffect(() => {
    return () => {
      dispatch(clearSearchResultsAction());
    };
  }, []);

  if (!searchResults) {
    return null;
  }

  return (
    <PageGrid container wide="true">
      <div>
        {searchResults.length > 0 ? (
          <ShowsList shows={searchResults} />
        ) : (
          <TitleTypography align="center" variant="h4">
            Nothing found :(
          </TitleTypography>
        )}
      </div>
    </PageGrid>
  );
};

export default SearchPage;
