import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchShowsSearchResult } from "pages/Search/api/searchApi";
import { searchModuleName } from "pages/Search/store/searchConstants";
import { handleApiError } from "utils";

export const searchShowsThunk = createAsyncThunk(
  `${searchModuleName}/searchShowsThunk`,
  async (searchStr) => {
    try {
      return await apiFetchShowsSearchResult(searchStr);
    } catch (error) {
      handleApiError(error);
    }
  },
);
