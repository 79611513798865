import { createSlice } from "@reduxjs/toolkit";
import { sessionModuleName } from "session/store/sessionConstants";
import { fetchSessionThunk } from "session/store/sessionThunks";

const initialState = {
  initialized: false,
  loggedIn: "true" === localStorage.getItem("loggedIn"),
  userInfo: { email: "default" },
};

export const guestUserState = {
  initialized: false,
  loggedIn: false,
  userInfo: { email: "default" },
};

const sessionSlice = createSlice({
  name: sessionModuleName,
  initialState,
  reducers: {
    loginAction: (state) => {
      localStorage.setItem("loggedIn", "true");
      state.initialized = false;
      state.loggedIn = true;
    },
    logoutAction: () => {
      localStorage.removeItem("loggedIn");
      return { ...guestUserState }; // setting state by return new value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessionThunk.fulfilled, (state, { payload }) => {
      state.initialized = payload.initialized;
      state.loggedIn = payload.loggedIn;
      state.userInfo = payload.userInfo;
    });
  },
});

export const { loginAction, logoutAction } = sessionSlice.actions;

export default sessionSlice.reducer;
