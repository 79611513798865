import React from "react";

import ShowCard from "components/ShowsList/ShowCard";
import PropTypes from "prop-types";
import { AutoFitGrid, FixedWidthGridItem } from "styled";

const ShowsList = React.memo(({ shows }) => {
  return (
    <AutoFitGrid container>
      {shows.map((show) => (
        <FixedWidthGridItem key={show.id} item width={200}>
          <ShowCard key={show.id} show={show} />
        </FixedWidthGridItem>
      ))}
    </AutoFitGrid>
  );
});

ShowsList.displayName = "ShowsList";

ShowsList.propTypes = {
  shows: PropTypes.array,
};

export default ShowsList;
