import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import EpisodeCard from "pages/NewEpisodes/components/EpisodeCard";
import { clearNewEpisodesAction } from "pages/NewEpisodes/store/newEpisodesSlice";
import { fetchNewEpisodesThunk } from "pages/NewEpisodes/store/newEpisodesThunks";
import { useDispatch, useSelector } from "react-redux";
import { AutoFitGrid, PageGrid, TitleTypography } from "styled";

const NewEpisodesPage = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.session);
  const { newEpisodes } = useSelector((state) => state.newEpisodes);

  useEffect(() => {
    document.title = "New episodes | MySeries";
    dispatch(fetchNewEpisodesThunk());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearNewEpisodesAction());
    };
  }, []);

  if (!loggedIn) {
    return (
      <PageGrid container>
        <Grid item>
          <TitleTypography variant="h4">Login Required ...</TitleTypography>
        </Grid>
      </PageGrid>
    );
  }

  if (!newEpisodes) {
    return null;
  }

  if (newEpisodes.length === 0) {
    return (
      <PageGrid container>
        <Grid item>
          <TitleTypography variant="h4">No new episodes</TitleTypography>
        </Grid>
      </PageGrid>
    );
  }

  return (
    <PageGrid container>
      <Grid item>
        <TitleTypography variant="h4">New Episodes</TitleTypography>
      </Grid>
      <AutoFitGrid container>
        {newEpisodes.map((episode) => (
          <Grid key={episode.id} item>
            <EpisodeCard episode={episode} />
          </Grid>
        ))}
      </AutoFitGrid>
    </PageGrid>
  );
};

export default NewEpisodesPage;
