import { createSlice } from "@reduxjs/toolkit";
import { showListModuleName } from "pages/ShowList/store/showListConstants";
import { fetchShowsListThunk } from "pages/ShowList/store/showListThunks";

const initialState = { shows: null };

const showListSlice = createSlice({
  name: showListModuleName,
  initialState,
  reducers: {
    clearShowsListAction: (state) => {
      state.shows = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShowsListThunk.fulfilled, (state, { payload }) => {
      state.shows = payload;
    });
  },
});

export const { clearShowsListAction } = showListSlice.actions;

export default showListSlice.reducer;
