import React, { useEffect } from "react";

import { Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import SeasonsTableWrapper from "pages/Show/components/SeasonsTable/SeasonsTableWrapper";
import ShowInfoBlock from "pages/Show/components/ShowInfoBlock/ShowInfoBlock";
import { clearShowAction } from "pages/Show/store/showSlice";
import { fetchShowThunk } from "pages/Show/store/showThunks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DescriptionTypography,
  FixedWidthGridItem,
  HorizontalGrid,
  PageGrid,
  TitleTypography,
} from "styled";

const ShowPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { show } = useSelector((state) => state.show);

  useEffect(() => {
    dispatch(fetchShowThunk(id));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearShowAction());
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.title = `${show.name} | MySeries`;
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const { name, pictureUrl, description } = show;

  return (
    <PageGrid container>
      <Grid item>
        <TitleTypography variant="h4">{name}</TitleTypography>
      </Grid>
      <HorizontalGrid container>
        <FixedWidthGridItem item>
          <CardMedia
            component="img"
            alt={name}
            image={pictureUrl ? pictureUrl : "/default-pic.png"}
            title={name}
            style={{ height: "441px", width: "300px" }}
          />
        </FixedWidthGridItem>
        <FixedWidthGridItem item>
          <ShowInfoBlock />
        </FixedWidthGridItem>
      </HorizontalGrid>
      <Grid item>
        <DescriptionTypography variant="body1">
          {description}
        </DescriptionTypography>
      </Grid>
      <Grid sx={{ width: "100%" }} item>
        <SeasonsTableWrapper />
      </Grid>
    </PageGrid>
  );
};

export default ShowPage;
