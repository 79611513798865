import React, { useEffect } from "react";

import Typography from "@mui/material/Typography";
import { PageGrid, TitleTypography } from "styled";

const PrivacyPage = () => {
  useEffect(() => {
    document.title = "Privacy & GDPR | MySeries";
  });

  return (
    <PageGrid container>
      <TitleTypography variant="h5" gutterBottom>
        Privacy Policy & GDPR
      </TitleTypography>

      <TitleTypography variant="subtitle1" gutterBottom>
        Effective Date: 2023-09-07
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Welcome to myseries. At myseries, we value your privacy and want you to
        understand how we collect, use, and protect your personal information.
        This Privacy Policy outlines our practices for GUEST visitors (not
        logged in) and REGISTERED users.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        Information Collected for GUEST Visitors
      </TitleTypography>

      <Typography variant="body1" paragraph>
        For GUEST visitors who are not logged in, we do not track any personal
        information. We do not embed any trackers or display ads to GUEST
        visitors.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        Information Collected for REGISTERED Users
      </TitleTypography>

      <Typography variant="body1" paragraph>
        As a REGISTERED user, you can create an account using our SIGNUP FORM.
        When you register:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            <strong>Username and Password:</strong> You can use any valid email
            and create a password of your choice. Your password will be securely
            encrypted and stored in our database. We do not have access to your
            password.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Profile Information:</strong> We may collect and store your
            name and surname.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Avatar:</strong> A small avatar image URL may be stored for
            display in the upper-right corner of the user interface when you are
            logged in.
          </Typography>
        </li>
      </ul>

      <TitleTypography variant="h6" gutterBottom>
        Data Security
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Your data, including your name, surname, email (if provided), and
        encrypted password, is securely stored in our database. Access to this
        data is restricted to our backend services and authorized system
        administrators. We employ robust security measures to protect your
        information.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        Feedback and Comments
      </TitleTypography>

      <Typography variant="body1" paragraph>
        As of [Date when comments functionality was removed], the comments
        functionality on our Website has been removed. Therefore, no comments or
        forum posts can be made.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        Forgotten/Reset Password
      </TitleTypography>

      <Typography variant="body1" paragraph>
        Please note that the &quot;forgot/reset password&quot; functionality is
        currently unavailable (under development). We apologize for any
        inconvenience and will notify users once this feature becomes
        accessible.
      </Typography>

      <TitleTypography variant="h6" gutterBottom>
        Deleting Personal Data
      </TitleTypography>

      <Typography variant="body1" paragraph>
        You have the right to request the deletion of your personal data,
        including your name and email (if provided), as well as any associated
        activity (likes, subs, comments). To initiate this process, please send
        an email to our support team at{" "}
        <a
          href="mailto:support.myseries@icloud.com"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          support.myseries@icloud.com
        </a>
        . We will endeavor to delete your data within 1-2 working days upon
        receiving your request.
      </Typography>

      <TitleTypography variant="body1" paragraph>
        <strong>Changes to this Privacy Policy</strong>
      </TitleTypography>

      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We encourage you to review this policy periodically. The effective date
        of the policy will be updated accordingly.
      </Typography>

      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or your
        data, please contact us at{" "}
        <a
          href="mailto:support.myseries@icloud.com"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          support.myseries@icloud.com
        </a>
        .
      </Typography>

      <Typography variant="body1">Thank you for using myseries!</Typography>

      <Typography variant="body1">Myseries, Kyiv (Ukraine)</Typography>

      <Typography variant="body1">
        <a
          href="mailto:support.myseries@icloud.com"
          style={{ color: "primary" }}
        >
          support.myseries@icloud.com
        </a>
      </Typography>
    </PageGrid>
  );
};

export default PrivacyPage;
