import React, { useEffect } from "react";

import { Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import EpisodeInfoBlock from "pages/Episode/components/EpisodeInfoBlock/EpisodeInfoBlock";
import { clearEpisodeAction } from "pages/Episode/store/episodeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  DescriptionTypography,
  FixedWidthGridItem,
  HorizontalGrid,
  PageGrid,
  TitleTypography,
  VisibleStyledLink,
} from "styled";

const EpisodePage = () => {
  const dispatch = useDispatch();
  const { episode } = useSelector((state) => state.episode);

  useEffect(() => {
    if (episode) {
      document.title = `${episode.show.name}: S${episode.seasonNumber}E${episode.number} - ${episode.name} | MySeries`;
    }
  }, [episode]);

  useEffect(() => {
    return () => {
      dispatch(clearEpisodeAction());
    };
  }, []);

  if (!episode) {
    return null;
  }

  const { name, description, show, number, seasonNumber, pictureUrl } = episode;

  return (
    <PageGrid container>
      <Grid item>
        <TitleTypography variant="h5">
          <VisibleStyledLink to={`/show/${show.id}`}>
            {show.name}
          </VisibleStyledLink>
        </TitleTypography>
      </Grid>
      <HorizontalGrid container justifyContent={"flex-start"}>
        <TitleTypography variant="h5" noWrap>
          {`S${seasonNumber}E${number} - ${name}`}
        </TitleTypography>
      </HorizontalGrid>
      <HorizontalGrid container>
        <FixedWidthGridItem item>
          <CardMedia
            component="img"
            alt={name}
            image={pictureUrl ? pictureUrl : "/default-pic.png"}
            title={name}
            style={{ height: "168px", width: "300px" }}
          />
        </FixedWidthGridItem>
        <FixedWidthGridItem item>
          <EpisodeInfoBlock episode={episode} />
        </FixedWidthGridItem>
        <Grid item>
          <DescriptionTypography variant="body1">
            {description}
          </DescriptionTypography>
        </Grid>
      </HorizontalGrid>
    </PageGrid>
  );
};

export default EpisodePage;
