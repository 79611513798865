import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchUserInfo } from "session/api/sessionApi";
import { sessionModuleName } from "session/store/sessionConstants";
import { guestUserState } from "session/store/sessionSlice";

export const fetchSessionThunk = createAsyncThunk(
  `${sessionModuleName}/fetchSessionThunk`,
  async () => {
    if (localStorage.getItem("loggedIn") === "true") {
      try {
        let userInfo = await apiFetchUserInfo();
        return { initialized: true, loggedIn: true, userInfo: userInfo };
      } catch (error) {
        if (error.response && 401 === error.response.status) {
          localStorage.removeItem("loggedIn");
        }
        return { ...guestUserState, initialized: true };
      }
    } else {
      return { ...guestUserState, initialized: true };
    }
  },
);
