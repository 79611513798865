import React, { useMemo } from "react";

import SeasonsTable from "pages/Show/components/SeasonsTable/SeasonsTable";
import { useSelector } from "react-redux";

export const transformEpisodesToSeasons = (episodes) => {
  const seasonsMap = new Map();
  const seasonOrder = [];

  episodes.forEach((episode) => {
    if (!seasonsMap.has(episode.seasonNumber)) {
      seasonsMap.set(episode.seasonNumber, []);
      seasonOrder.push(episode.seasonNumber);
    }
    seasonsMap.get(episode.seasonNumber).push(episode);
  });

  return seasonOrder.map((seasonNumber) => {
    let seasonEpisodes = seasonsMap.get(seasonNumber);
    let seasonUserData = null;
    seasonEpisodes.forEach((episode) => {
      if (episode.userData) {
        if (seasonUserData == null) {
          seasonUserData = { isViewed: true };
        }
        if (!episode.userData.isViewed) {
          seasonUserData.isViewed = false;
        }
      }
    });
    return {
      seasonNumber,
      episodes: seasonEpisodes,
      userData: seasonUserData,
    };
  });
};

const SeasonsTableWrapper = () => {
  const { show, episodesChecksum } = useSelector((state) => state.show);
  const episodes = show ? show.episodes : [];
  const seasons = useMemo(
    () => transformEpisodesToSeasons(episodes),
    [episodesChecksum],
  );
  return <SeasonsTable showId={show.id} seasons={seasons} />;
};

export default SeasonsTableWrapper;
