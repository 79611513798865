import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFetchShowInfo } from "pages/Show/api/showApi";
import { showModuleName } from "pages/Show/store/showConstants";
import { handleApiError } from "utils";

export const fetchShowThunk = createAsyncThunk(
  `${showModuleName}/fetchShowThunk`,
  async (id) => {
    try {
      return await apiFetchShowInfo(id);
    } catch (error) {
      handleApiError(error);
    }
  },
);
