import React, { useCallback } from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import {
  apiResumeWatchingShow,
  apiStopWatchingShow,
} from "pages/Show/api/showApi";
import { fetchShowThunk } from "pages/Show/store/showThunks";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { handleApiError } from "utils";

const PauseButton = React.memo(({ id, isActive }) => {
  const dispatch = useDispatch();

  const createClickHandler = useCallback(
    (isActive) => async () => {
      try {
        if (isActive) {
          await apiStopWatchingShow(id);
        } else {
          await apiResumeWatchingShow(id);
        }
        dispatch(fetchShowThunk(id));
      } catch (error) {
        handleApiError(error);
      }
    },
    [id],
  );

  let onClick = createClickHandler(isActive);

  return (
    <div>
      <Button
        sx={{ width: "200px" }}
        variant={isActive ? "outlined" : "contained"}
        onClick={onClick}
        endIcon={isActive ? <DoneIcon /> : null}
      >
        {isActive ? "WATCHING" : "RESUME WATCHING"}
      </Button>
    </div>
  );
});

PauseButton.displayName = "PauseButton";

PauseButton.propTypes = {
  id: PropTypes.number,
  isActive: PropTypes.bool,
};

export default PauseButton;
