import { createSlice } from "@reduxjs/toolkit";
import { searchModuleName } from "pages/Search/store/searchConstants";
import { searchShowsThunk } from "pages/Search/store/searchThunks";

const initialState = { searchResults: null };

const searchSlice = createSlice({
  name: searchModuleName,
  initialState,
  reducers: {
    clearSearchResultsAction: (state) => {
      state.searchResults = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchShowsThunk.fulfilled, (state, { payload }) => {
      state.searchResults = payload;
    });
  },
});

export const { clearSearchResultsAction } = searchSlice.actions;

export default searchSlice.reducer;
