import { createSlice } from "@reduxjs/toolkit";
import { episodeModuleName } from "pages/Episode/store/episodeConstants";
import { fetchEpisodeThunk } from "pages/Episode/store/episodeThunks";

const initialState = { episode: null };

const episodeSlice = createSlice({
  name: episodeModuleName,
  initialState,
  reducers: {
    clearEpisodeAction: (state) => {
      state.episode = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEpisodeThunk.fulfilled, (state, { payload }) => {
      state.episode = payload;
    });
  },
});

export const { clearEpisodeAction } = episodeSlice.actions;

export default episodeSlice.reducer;
