import React, { useCallback } from "react";

import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { apiMarkEpisodeViewed } from "pages/NewEpisodes/api/newEpisodes";
import { fetchNewEpisodesThunk } from "pages/NewEpisodes/store/newEpisodesThunks";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { StyledLink } from "styled";
import { handleApiError } from "utils";

const EpisodeCard = ({ episode }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    id,
    name,
    seasonNumber,
    number,
    description,
    pictureUrl,
    showId,
    showName,
    showPictureUrl,
  } = episode;

  const episodePicUrl = pictureUrl ? pictureUrl : showPictureUrl;

  let onClick = useCallback(async () => {
    try {
      await apiMarkEpisodeViewed(id);
      dispatch(fetchNewEpisodesThunk());
    } catch (error) {
      handleApiError(error);
    }
  }, [id]);

  return (
    <Card
      sx={{
        maxWidth: theme.columnWidth,
        minWidth: theme.columnWidth,
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={episodePicUrl ? episodePicUrl : "/default-pic.png"}
      />
      <CardContent
        sx={{
          minHeight: 140,
          maxHeight: 140,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography gutterBottom variant="h5">
          <StyledLink to={`/show/${showId}`}>{showName}</StyledLink>
        </Typography>
        <StyledLink to={`/episode/${id}`}>
          <Typography gutterBottom variant="h5" noWrap>
            {`S${seasonNumber}-E${number}: ${name}`}
          </Typography>
        </StyledLink>
        {description ? (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        ) : null}
      </CardContent>

      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={onClick}
          variant={"contained"}
          size="small"
          sx={{ width: "100%" }}
        >
          MARK VIEWED
        </Button>
      </CardActions>
    </Card>
  );
};

EpisodeCard.propTypes = {
  episode: PropTypes.object,
};

export default EpisodeCard;
