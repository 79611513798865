import React, { useEffect } from "react";

import { Pagination } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import ShowsList from "components/ShowsList/ShowsList";
import { clearShowsListAction } from "pages/ShowList/store/showListSlice";
import { fetchShowsListThunk } from "pages/ShowList/store/showListThunks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PageGrid } from "styled";

const ShowListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageNumber = parseInt(searchParams.get("page") || "1", 10);
  const category = searchParams.get("category") || "ALL";

  const { loggedIn } = useSelector((state) => state.session);
  const { shows } = useSelector((state) => state.showList);

  useEffect(() => {
    document.title = "MySeries";
    dispatch(fetchShowsListThunk({ category, pageNumber }));
  }, [category, pageNumber]);

  useEffect(() => {
    return () => {
      dispatch(clearShowsListAction());
    };
  }, []);

  const onChangePage = (event, value) => {
    navigate(`?page=${value}&category=${category}`);
  };

  const onChangeCategory = (event, newValue) => {
    navigate(`?page=1&category=${newValue}`);
  };

  if (!shows) {
    return null;
  }

  return (
    <PageGrid container wide="true">
      {loggedIn ? (
        <Tabs
          value={category}
          onChange={onChangeCategory}
          aria-label="category filter"
        >
          <Tab label="All" value="ALL" />
          <Tab label="Watching" value="WATCHING" />
          <Tab label="Stopped" value="STOPPED" />
          <Tab label="Finished" value="FINISHED" />
        </Tabs>
      ) : null}

      {shows ? (
        <>
          <ShowsList shows={shows.shows} />
          {category === "ALL" ? (
            <Pagination
              count={shows.totalPages}
              page={pageNumber}
              onChange={onChangePage}
              siblingCount={0}
              boundaryCount={1}
            />
          ) : null}
        </>
      ) : null}
    </PageGrid>
  );
};

export default ShowListPage;
